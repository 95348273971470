import React from 'react'
import { Col, Row } from 'react-bootstrap'
import BaseLayout from '../components/base-layout'
import SignupForm from '../components/sign-up-form'
import SearchBox from '../components/search-box'

// Creates the front page of the app; a searchbar which leads users to the script they need.
const AppPage = () => {
  return (
      <BaseLayout pageTitle="App">
        <Row>
          <Col>
            <h1 class="display-1" style={{textAlign:"center"}}>The two-step TNNL process</h1>
          </Col>
        </Row>
        <Row xs={1} md={2} style={{marginTop:"auto", marginBottom:"auto"}}>
          <Col>
            <h6 class="display-6">Step 1: Search for the tool you need</h6>
            <p class="lead">Use the search bar to describe the task you need help with, then click "Search". You will instantly be taken to the best tool for your task.</p>
          </Col>
          <Col style={{margin:"auto"}}>
            {SearchBox()}
          </Col>
        </Row>
        <Row style={{marginTop:"auto", marginBottom:"auto"}}>
          <Col xs={12} md={6}>
          <h6 class="display-6">Step 2: Follow the tool instructions</h6>
          <p class="lead">Each tool has a description of what it will do and instructions on how to use it. Our tools are designed to be easy to use, lightning-fast and hassle-free so that they <em>just work</em>.</p>
          </Col>
        </Row>
        <Row style={{marginTop:"auto", marginBottom:"auto"}}>
          <Col>
          <h6 class="display-6" style={{textAlign:"center"}}>Get involved</h6>
          <p class="lead">
            We want TNNL to be the go-to place for any tools you may need to help complete your day-to-day tasks faster. 
            If you are interested in using TNNL for your business, please leave your email address below and we will contact you within one day.
          </p>
          <SignupForm/>
          </Col>
        </Row>
      </BaseLayout>
  )
}

export default AppPage